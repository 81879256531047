<template>
  <v-switch v-model="operator.deactivated"
            :false-value="true"
            :true-value="false"
            @change="toggle"
            :loading="working"
            :disabled="working"
            inset>
  </v-switch>
</template>

<script>
export default {
  name: 'OperatorActivationSwitch',
  props: ['operator'],
  data: () => ({
    working: false
  }),
  methods: {
    async toggle() {
      this.working = true;
      const data = new FormData;
      data.append('deactivated', this.operator.deactivated);

      try {
        await this.$api.fetch(`/billing/operators/${this.operator.id}`, { method: 'PUT', body: data });
        this.$emit('change', this.operator.deactivated);
      } catch (e) {
        console.error(e);
        this.operator.deactivated = !this.operator.deactivated;
      }

      this.working = false;
    }
  }
}
</script>
