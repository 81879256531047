<i18n>
{
  "en": {
    "agent-management-title": "Agent Management",
    "subtitle-explanation": "Agents active within any given month will incur a fixed fee for that month. Deactivated agents are automatically reactivated when they make calls through LiveCall for LINE WORKS. Edit permission settings in the {0} to manage agent access to the LiveCall for LINE WORKS bot.",
    "lineworks-admin": "LINE WORKS Admin console",
    "agents": "Agents",
    "agents-loading-error": "Error loading agents",
    "account-deactivates-next-month": "The account will deactivate at the end of the month.",
    "no-agents": "No agents have interacted with the bot yet.",
    "loading-error": "An error occurred while loading the agent list. Please try again later.",
    "active-agent-count": "No active agents | 1 active agent | {0} active agents",
    "period": "{0} to {1}",
    "active-operators": "no agents between {start} and {end}) | {count} agent between {start} and {end} | {count} agents between {start} and {end}",
    "estimated-cost": "Estimated cost: {0}",
    "latest-activity": "Latest activity: {0}",
    "never": "never"
  },
  "ja": {
    "agent-management-title": "スタッフ管理",
    "subtitle-explanation": "LiveCall for LINE WORKSボットを有効化したスタッフアカウントごとに月額費用が発生します。ボットを介してLiveCallにログインすると、自動的にアカウントが再有効化されます。各スタッフのボットへのアクセスを制限したい場合は {0} で使用権限を変更してください。",
    "lineworks-admin": "LINE WORKS 管理者画面",
    "agents": "スタッフ",
    "agents-loading-error": "読み込みエラー",
    "account-deactivates-next-month": "このアカウントは既に今月の通話利用があるため、来月よりアカウントが無効化されます。（今月分の月額は課金されます）",
    "no-agents": "ボットを導入したスタッフは、まだいません。",
    "loading-error": "スタッフリストの読み込みに失敗しました。ページを更新の上、再度お試しください。",
    "active-agent-count": "ボット導入スタッフ：{0}人",
    "period": "{0} 〜 {1}",
    "active-operators": "ボット導入スタッフ：{count}人（期間： {start} 〜 {end}）",
    "estimated-cost": "現時点までの月額基本料：{0}",
    "latest-activity": "最新の活動：{0}",
    "never": "未済"
  }
}
</i18n>
<template>
  <v-card elevation="4">
    <v-card-title>{{ $t('agent-management-title') }}</v-card-title>
    <v-card-subtitle>
      <i18n path="subtitle-explanation">
        <a href="https://admin.worksmobile.com/service/bot" target="_blank" class="text-decoration-none">
          <v-icon x-small>mdi-open-in-new</v-icon>
          {{ $t('lineworks-admin') }}
        </a>
      </i18n>
    </v-card-subtitle>
    <v-card-text>
      <v-expansion-panels v-model="panel" hover>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters v-if="agents !== null">
              <v-col cols="4" sm="3">
                {{ $tc('active-agent-count', agents, [agents]) }}
              </v-col>
              <v-col cols="4" sm="4">
                {{ $t('estimated-cost', [$n(estimatedCost.split(' ')[1], 'currency')]) }}
              </v-col>
              <v-col cols="4" sm="5" class="text--secondary text-right">
                {{
                  $t('period', [
                    formatDate(agentPeriodStart, 'Asia/Tokyo', shortDateFormat),
                    formatDate(agentPeriodEnd, 'Asia/Tokyo', shortDateFormat)
                  ])
                }}
              </v-col>
            </v-row>
            <span v-else-if="agentsLoadingError" class="error--text">
              {{ $t('agents-loading-error') }}
            </span>
            <span v-else>
              <v-progress-circular indeterminate size="16" width="2" style="margin-right: .5em"></v-progress-circular>
              {{ $t('agents') }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="list-item-two-line@2" :loading="loading">
              <v-list>
                <v-list-item v-for="item in data" :key="item.operator.id"
                             :two-line="!isSetToDeactivateNextMonth(item)"
                             :three-line="isSetToDeactivateNextMonth(item)">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.operator.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t('latest-activity', [item.latest_call ? formatDate(item.latest_call) : $t('never')]) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="isSetToDeactivateNextMonth(item)">
                      <v-alert dense text type="warning">{{ $t('account-deactivates-next-month') }}</v-alert>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <activation-switch :operator="item.operator" @change="loadOverview"></activation-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="!data || data.length === 0">
                  <v-list-item-content>
                    <v-alert v-if="loadingError" outlined type="error">{{ $t('loading-error') }}</v-alert>
                    <v-alert v-else outlined type="info">{{ $t('no-agents') }}</v-alert>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-pagination v-if="pages > 1" v-model="page" :length="pages"></v-pagination>
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';
import ActivationSwitch from './OperatorActivationSwitch';

export default {
  name: 'OperatorManagementWidget',
  components: {
    ActivationSwitch
  },
  data: () => ({
    data: null,
    page: 1,
    pages: null,
    limit: 25,
    total: null,
    panel: undefined,
    loadingError: false,
    agents: null,
    agentsLoadingError: false,
    agentPeriodStart: null,
    agentPeriodEnd: null,
    estimatedCost: null,
    shortDateFormat: DateTime.DATE_MED
  }),
  computed: {
    loading() {
      return this.data === null;
    }
  },
  watch: {
    async page() {
      await this.loadData();
    },
    async panel(isShown) {
      if (typeof isShown !== 'undefined' && !this.data) {
        await this.loadData();
      }
    }
  },
  async mounted() {
    await this.loadOverview();
  },
  methods: {
    async loadOverview() {
      const response = await this.$api.fetch('/billing/operators/active');

      if (!response.ok) {
        console.error(response);
        this.agentsLoadingError = true;
        return;
      }

      const data = await response.json();
      this.agents = data.operators;
      this.agentPeriodStart = data.start;
      this.agentPeriodEnd = data.end;
      this.estimatedCost = data.cost;
    },
    async loadData() {
      this.loadingError = false;

      /** @type {Response} */
      const response = await this.$api.fetch(`/billing/operators?page=${this.page}&limit=${this.limit}`);

      if (!response.ok) {
        console.error(response);
        this.data = [];
        this.loadingError = true;
        return;
      }

      const data = await response.json();
      this.data = data.results;
      this.pages = data.pages;
      this.total = data.total;
    },
    isThisMonth(ts) {
      const now = DateTime.now().setZone('Asia/Tokyo');
      // noinspection JSCheckFunctionSignatures
      const date = DateTime.fromSeconds(ts, { zone: 'Asia/Tokyo' });
      return now.year === date.year && now.month === date.month;
    },
    isSetToDeactivateNextMonth(item) {
      try {
        return item.operator.deactivated && this.isThisMonth(item.latest_call);
      } catch {
        return false;
      }
    },
    formatDate(ts, zone = 'local', format = DateTime.DATETIME_MED_WITH_WEEKDAY) {
      // noinspection JSCheckFunctionSignatures
      return DateTime.fromSeconds(ts, { zone, locale: this.$i18n.locale }).toLocaleString(format);
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.v-alert {
  margin-bottom: 0;
  margin-top:    .5em;
}
</style>